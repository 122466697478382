<template>
  <v-navigation-drawer app  permanent floating expand-on-hover v-model="abrir" class="nav">
    <v-list-item class="px-2">
            <v-list-item-avatar>
              <v-img :src="require('@/assets/logo.png')"></v-img>
            </v-list-item-avatar>
          </v-list-item>
    <v-list>
      <v-list-group
        v-for="menu in menus"
        :key="menu.id"
        :prepend-icon="menu.icone"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="menu.label" />
          </v-list-item-content>
        </template>
        <v-list-item
          v-for="menuFilho in menu.menus"
          :key="menuFilho.id"
          :to="{ name: menuFilho.rota }"
          link
        >
          <v-list-item-content>
            <v-list-item-title v-text="menuFilho.label" />
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
    <template v-slot:append>
      <v-list>
        <v-list-item link @click="sair">
          <v-list-item-icon>
            <v-icon>mdi-power</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import store from '@/store'
import { mapActions } from 'vuex'
import menuConfig from '@/menu'

export default {
  data() {
    return {
      menus: [],
      abrir: false,
    }
  },
  mounted() {
    const mestre = store.getters['rotaMestre']
    const permissoesUsuario = store.getters['rotas']

    if (mestre) {
      this.menus = menuConfig
    } else {
      const menusTmp = menuConfig

      this.menus = menusTmp
        .map((menuRaiz) => {
          menuRaiz.menus = menuRaiz.menus.filter((rota) => {
            return permissoesUsuario.includes(rota.permissaoNecessaria)
          })
          return menuRaiz
        })
        .filter((menuRaiz) => menuRaiz.menus.length > 0)
    }

    this.abrir = !this.$vuetify.breakpoint.xsOnly
  },
  methods: {
    ...mapActions(['sairDoSistemaStore']),
    abrirMenu() {
      this.abrir = true
    },
    sair() {
      this.sairDoSistemaStore()
      location.reload()
    },
  },
}
</script>

<style lang="scss">

.nav {
  background-color: #d5dee8 !important;
}

</style>