<template>
  <validation-provider
    :vid="id"
    :name="id"
    :rules="validations"
    v-slot="{ errors }"
  >
    <v-text-field
      :label="label"
      :error-messages="errors"
      :placeholder="placeholder"
      :disabled="disabled"
      :clearable="clearable"
      :autocomplete="false"
      v-model="inputValue"
      @blur="blur"
    />
  </validation-provider>
</template>

<!-- v-currency="{
  currency: null,
  locale: 'pt-BR',
  allowNegative: false,
  valueRange: { min: 0 },
}" -->

<script>
import { CurrencyDirective } from 'vue-currency-input'
import InputMixin from './InputMixin'

export default {
  mixins: [InputMixin],
  directives: {
    currency: CurrencyDirective,
  },
}
</script>
