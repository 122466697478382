<template>
  <l-tela>
    <l-form :salvar="login">
      <i-basico
        id="usuario"
        label="Usuário"
        v-model="form.login"
        :validations="{ required: true, min: 4 }"
      />

      <i-basico
        id="senha"
        label="Senha"
        v-model="form.senha"
        type="password"
        :validations="{ required: true, min: 6 }"
      />

      <v-btn class="botao-login" color="primary" type="submit">Entrar</v-btn>
    </l-form>
  </l-tela>
</template>

<script>
import LoadingMixin from '@/components/Mixins/Loading'
import { mapActions } from 'vuex'

export default {
  mixins: [LoadingMixin],
  data() {
    return {
      form: {
        login: '',
        senha: '',
      },
    }
  },
  methods: {
    ...mapActions(['realizaLoginStore', 'buscaRotasPermissaoStore']),
    async login() {
      this.mostrarLoading()
      try {
        const { cargo } = await this.realizaLoginStore({ ...this.form })
        await this.buscaRotasPermissaoStore(cargo)
        await this.$router.push({ name: 'rota-pedidos-agenda' })
      } catch (error) {
        console.log(error)
      } finally {
        this.fecharLoading()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.botao-login {
  margin-top: 1.2rem;
}
</style>
